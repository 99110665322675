import React, {Component} from "react";
import amazonLogo from '../amazon_logo.svg'
import '../css/App.css';
import '../css/LoginForm.css';

// TODO- Hardcoding the Alpha Endpoint for testing purposes. Replace with config based routing before launch
const alphaLoginEndpoint = "https://test.device.devluna.people.amazon.dev/login";

function areCredentialsInvalid(queryParams: string) {
    return queryParams.search("error=invalid-credentials") !== -1;
}


class LoginPage extends Component {
    state = {
        csrfToken: undefined,
        showInvalidCredentialsBanner: false
    }

    componentDidMount() {
        let invalidCredentials = false;
        if(areCredentialsInvalid(window.location.search)) {
            invalidCredentials = true;
        }
        fetch(alphaLoginEndpoint.concat(window.location.search), {
        }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({
                csrfToken: data._csrf,
                showInvalidCredentialsBanner: invalidCredentials
            });
        });
    }

    render() {
        let invalidCredentialsBanner = <></>;
        if (this.state.showInvalidCredentialsBanner) {
            invalidCredentialsBanner = <p className="error-message">Invalid credentials entered. Please retry.</p>;
        }
        return (
        <form method="POST" action={`${alphaLoginEndpoint}${window.location.search}`}className="IhmLoginForm">
            <img className="amazon-logo" src={amazonLogo} alt="Amazon Logo"/>
            {invalidCredentialsBanner}
            <input name="_csrf" type="hidden" defaultValue={this.state.csrfToken} />
            <input className="form-group form-control" type="text" name="username"
                   placeholder="User Name" required autoFocus/>
            <input className="form-group form-control" type="password" name="password"
                   placeholder="PIN" required />
            <button type="submit" className="form-group btn-yellow">Login</button>
        </form>
    )};
}

export default LoginPage;
