import React, {Component} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './css/App.css';
import LoginPage from "./components/LoginPage";
import ErrorPage from "./components/ErrorPage";

class App extends Component {
    render () {
      return (
          <BrowserRouter>
              <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/error" element={<ErrorPage />} />
              </Routes>
          </BrowserRouter>
      );
  }
}

export default App;