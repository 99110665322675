import React, {Component} from "react";
import amazonLogo from "../amazon_logo.svg";

class ErrorPage extends Component {
    render() {
        return (
            <div>
                <img className="amazon-logo" src={amazonLogo} alt="Amazon Logo"/>
                <h4>An error was encountered with the requested page. </h4>
            </div>
        )
    };
}

export default ErrorPage;
